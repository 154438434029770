.error-page {
	min-height: 100vh;
	padding: $spacer * 4 0;
	display: flex;
	align-items: center;

	& .error-page-content {
		margin: 0 auto;
		position: relative;
		padding: 0 $spacer;
		text-align: center;

		& .error-code {
			color: $white;
			left: 0;
			right: 0;
			font-size: rem-num(118px);
			font-weight: $font-weight-bold;
			text-align: center;
			line-height: 1.25;
			letter-spacing: 5px;
			text-shadow: 10px 10px rgba($white, .15);

			@include media-breakpoint-down(sm) {
				font-size: rem-num(102px);
			}
		}

		& .error-page-icon i {
			font-size: rem-num(128px);
			color: $gray-300;
		}

		& h1 {
			font-size: rem-num(48px);
			font-weight: $font-weight-bold;

			@include media-breakpoint-down(sm) {
				font-size: rem-num(52px);
			}
		}

		& h3 {
			font-size: rem-num(18px);
			font-weight: 400;

			@include media-breakpoint-down(sm) {
				font-size: rem-num(16px);
			}
		}

		& .link-divider {
			width: rem-num(4px);
			height: rem-num(4px);
			display: inline-block;
			background: rgba($white, .3);
			position: relative;
			top: rem-num(-2px);
			margin: 0 $spacer;
			border-radius: $border-radius;
		}
	}
}