.ant-collapse-header-text {
    color: #2f3030;
}

.ant-collapse-content {
    background: white !important;
}

.share .ant-tabs-tab-btn {
    color: #2f3030 !important;
}