.mailbox {
	height: 100%;
	display: flex;
	flex-direction: column;

	& .mailbox-toolbar {
		padding: $spacer * .75;
		display: flex;

		@include media-breakpoint-down(lg) {
			overflow: scroll;
			white-space: nowrap;
			padding: $spacer * .5;
			flex-wrap: nowrap;
		}

		& .mailbox-toolbar-text {
			color: rgba($white, .5);
			padding: $spacer * .5;

			@include media-breakpoint-down(lg) {
				padding: 0;
			}
		}

		& .mailbox-toolbar-link {
			padding: $spacer * .5 $spacer;
		}

		& .mailbox-toolbar-item {
			padding: $spacer * .5;
		}

		& .mailbox-toolbar-link {
			color: rgba($white, .75);
			text-decoration: none;
			border-radius: $border-radius;

			&:hover {
				background: rgba($white, .25);
			}

			&.active {
				background: var(--bs-theme);
				color: $component-active-color;
				font-weight: $font-weight-bold;
			}
		}
	}

	& .mailbox-body {
		border-top: 1px solid $border-color;
		overflow: hidden;
		display: flex;
		flex: 1;

		& .mailbox-sidebar {
			max-width: rem-num(360px);
			height: 100%;

			@include media-breakpoint-down(lg) {
				max-width: 100%;
				height: auto;
			}

			&+.mailbox-content {
				@if ($enable-rtl) {
					border-right: 1px solid $border-color;
				}

				@else {
					border-left: 1px solid $border-color;
				}

				@include media-breakpoint-down(lg) {
					@if ($enable-rtl) {
						border-right: none;
					}

					@else {
						border-left: none;
					}
				}
			}
		}

		& .mailbox-content {
			height: 100%;
			flex: 1;
		}
	}

	& .mailbox-empty-message {
		min-height: 100%;
		width: 100%;
		padding: $spacer * 2;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& .mailbox-empty-message-icon {
			& i {
				font-size: $font-size-base * 7;
			}
		}

		& .mailbox-empty-message-img {
			width: 100%;
			text-align: center;
			margin-bottom: $spacer;

			& img {
				width: 100%;
				height: 100%;
				max-width: rem-num(480px);
				max-height: rem-num(480px);
				opacity: 0.5
			}
		}

		& .mailbox-empty-message-title {
			font-size: $font-size-base * 1.5;
			font-weight: $font-weight-bold;
			color: rgba($white, .5);
		}
	}

	& .mailbox-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		& .mailbox-list-item {
			padding: $spacer;
			display: flex;

			&+.mailbox-list-item {
				border-top: 1px solid $border-color;
			}

			&:hover {
				background-color: rgba($white, .1);
			}

			&.unread {
				& .mailbox-message {
					& .mailbox-title {
						font-weight: $font-weight-bold;
						color: $white;
					}
				}
			}

			&.active {
				background: rgba($white, .1);
			}

			&.has-attachment {
				& .mailbox-checkbox:after {
					content: '\f0c1';
					position: absolute;
					top: rem-num(25px);
					text-align: center;
					color: rgba($white, .5);
					width: rem-num(18px);

					@include fontawesome();

					@if ($enable-rtl) {
						right: 0;
					}

					@else {
						left: 0;
					}
				}
			}
		}

		& .mailbox-checkbox {
			position: relative;

			&+.mailbox-message {
				@if ($enable-rtl) {
					padding-right: $spacer * .5;
				}

				@else {
					padding-left: $spacer * .5;
				}
			}
		}

		& .mailbox-message {
			flex: 1;

			& .mailbox-list-item-link {
				display: block;
				color: $white;
				text-decoration: none;
				position: relative;
			}

			& .mailbox-sender {
				position: relative;
				display: flex;
				align-items: center;

				@include media-breakpoint-down(lg) {
					display: block;
				}

				& .mailbox-sender-name {
					font-weight: $font-weight-bold;
					color: var(--bs-theme);
					flex: 1;

					@if ($enable-rtl) {
						margin-left: auto;
					}

					@else {
						margin-right: auto;
					}
				}

				& .mailbox-time {
					font-size: $font-size-base - rem-num(2px);
					color: rgba($white, .5);
					font-weight: $font-weight-base;

					@if ($enable-rtl) {
						padding-right: $spacer * .5;
					}

					@else {
						padding-left: $spacer * .5;
					}
				}
			}

			& .mailbox-title {
				color: rgba($white, .75);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: rem-num(290px);

				@include media-breakpoint-down(lg) {
					max-width: initial;
					white-space: initial;
				}
			}

			& .mailbox-desc {
				position: relative;
				font-size: $font-size-base - rem-num(1px);
				color: rgba($white, .5);
				max-height: rem-num(42px);
				overflow: hidden;
			}
		}
	}

	& .mailbox-detail {
		padding: $spacer * 1.5;

		@include media-breakpoint-down(lg) {
			padding: $spacer;
		}

		& .mailbox-detail-header {
			border-bottom: 1px solid $border-color;
			padding: 0 0 $spacer * 1.5;

			@include media-breakpoint-down(lg) {
				padding-bottom: $spacer;
			}
		}

		& .mailbox-detail-content {
			padding: $spacer * 1.5 0;

			@include media-breakpoint-down(lg) {
				padding: $spacer 0;
			}
		}

		& .mailbox-detail-body {
			font-size: $font-size-base;
		}

		& .mailbox-detail-attachment {
			display: flex;
			flex-wrap: wrap;

			& .mailbox-attachment {
				background: rgba($white, .15);
				width: rem-num(120px);
				overflow: hidden;
				margin-bottom: $spacer * .5;
				border-radius: $border-radius;

				@if ($enable-rtl) {
					margin-left: $spacer * .5;
				}

				@else {
					margin-right: $spacer * .5;
				}

				& a {
					display: block;
					text-decoration: none;
				}

				& .document-file {
					height: rem-num(60px);
					overflow: hidden;
					text-align: center;
					background: rgba($white, .1);

					& i {
						display: block;
						line-height: rem-num(60px);
						font-size: rem-num(32px);
						color: $white;
					}

					& img {
						max-width: 100%;
					}
				}

				& .document-name {
					color: $white;
					padding: rem-num(2px) rem-num(10px);
					font-size: $font-size-base - rem-num(1px);
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	& .mailbox-form {
		& .mailbox-form-header {
			padding: $spacer $spacer * 1.5;

			@include media-breakpoint-down(lg) {
				padding: $spacer * .75 $spacer;
			}

			& .rti--container {
				--rti-bg: #{rgba($white, .15)};
			}

			& .form-control {
				background: rgba($white, .15);
			}
		}

		& .quill {
			& .ql-toolbar {
				border-left: none;
				border-right: none;
			}

			& .ql-container {
				border: none;
			}
		}
	}
}