.page-header {
	color: $blue;
	padding: 0;
	border: none;
	margin-bottom: $spacer;
	font-weight: $font-weight-bold;
	font-size: rem-num(28px);

	& small {
		font-size: rem-num(16px);
		color: rgba($black, .8);
		font-weight: $font-weight-light;
	}
}