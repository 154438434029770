.nav-wizards-container {
	@include media-breakpoint-down(md) {
		overflow: scroll;

		& .nav.nav-wizards-1,
		& .nav.nav-wizards-2,
		& .nav.nav-wizards-3 {
			min-width: rem-num(600px);
		}
	}
}

.nav {
	&.nav-wizards-1 {
		& .nav-item {
			padding: 0;

			& .nav-link {
				position: relative;
				color: rgba($white, .75);

				& .nav-no {
					width: rem-num(40px);
					height: rem-num(40px);
					margin: 0 auto;
					font-weight: $font-weight-bold;
					background: none;
					border: 2px solid $border-color;
					position: relative;
					z-index: 10;
					border-radius: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all .2s linear;
				}

				& .nav-text {
					text-align: center;
					margin-top: rem-num(5px);
					font-weight: $font-weight-bold;
					font-size: $font-size-base - rem-num(1px);
				}

				&.active,
				&.completed {
					color: var(--bs-theme);

					& .nav-no {
						border-color: var(--bs-theme);
						color: var(--bs-theme);
					}

					&:before,
					&:after {
						background: var(--bs-theme);
					}
				}

				&.active {
					& .nav-no {
						box-shadow: 0 0 0 5px rgba(#{var(--bs-theme)}, .2);
					}
				}

				&.disabled {
					color: rgba($white, .25);
				}

				&:before {
					content: '';
					position: absolute;
					top: $nav-link-padding-x + rem-num(10px);
					height: rem-num(2px);
					background: rgba($white, .3);
					transition: all .2s linear;
				}

				&:before {
					left: calc(-50% + 20px);
					right: calc(50% + 20px);
				}
			}

			&:first-child {
				& .nav-link {
					&:before {
						display: none;
					}
				}
			}

			&:last-child {
				& .nav-link {
					&:after {
						display: none;
					}
				}
			}
		}
	}

	&.nav-wizards-2 {
		& .nav-item {
			padding: 0 $nav-link-padding-x;

			& .nav-link {
				position: relative;
				color: rgba($white, .75);
				background: rgba($white, .3);
				border-radius: 60px;

				& .nav-text {
					text-align: center;
					font-weight: $font-weight-bold;
					font-size: $font-size-base - rem-num(1px);
				}

				&:before {
					content: '';
					position: absolute;
					left: $nav-link-padding-x * -2;
					right: 100%;
					height: rem-num(2px);
					top: 50%;
					margin-top: rem-num(-1px);
					background-color: rgba($white, .3);
					transition: all .2s linear;
				}

				&.disabled {
					color: rgba($white, .25);
				}

				&.active,
				&.completed {
					background: var(--bs-theme);
					color: $component-active-color;

					&:before {
						background: var(--bs-theme);
					}
				}

				&.active {
					box-shadow: 0 0 0 4px rgba(#{var(--bs-theme)}, .2);
				}
			}

			&:first-child {
				& .nav-link {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	&.nav-wizards-3 {
		& .nav-item {
			padding: 0;

			& .nav-link {
				position: relative;
				color: rgba($white, .75);

				& .nav-dot {
					width: rem-num(16px);
					height: rem-num(16px);
					margin: 0 auto;
					font-weight: $font-weight-bold;
					background: none;
					border: 3px solid $border-color;
					position: relative;
					z-index: 10;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all .2s linear;
				}

				& .nav-title {
					margin-top: rem-num(10px);
					font-weight: $font-weight-bold;
					text-align: center;
				}

				& .nav-text {
					text-align: center;
					font-size: $font-size-base - rem-num(1px);
				}

				&.active,
				&.completed {
					color: var(--bs-theme);

					& .nav-dot {
						border-color: var(--bs-theme);
						color: var(--bs-theme);
					}

					&:before {
						background: var(--bs-theme);
					}
				}

				&.active {
					& .nav-dot {
						box-shadow: 0 0 0 5px rgba(#{var(--bs-theme)}, .2);
					}
				}

				&.disabled {
					color: rgba($white, .25);
				}

				&:before {
					content: '';
					position: absolute;
					left: calc(-50% + 8px);
					top: $nav-link-padding-y + rem-num(6px);
					right: calc(50% + 8px);
					height: rem-num(3px);
					background: rgba($white, .3);
				}
			}

			&:first-child {
				& .nav-link {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}