body .rti--container {
	background: transparent;
	border: 1px solid $input-border-color;
	border-radius: $border-radius;
	padding: $input-padding-y $input-padding-x;
	line-height: 1;
	gap: rem-num(4px);

	&:focus-within {
		border-color: $input-focus-border-color;
		box-shadow: $input-focus-box-shadow;
	}

	& .rti--tag {
		font-size: rem-num(12px);
		color: $white;
		line-height: rem-num(15px);
		min-height: rem-num(20px);
		font-weight: 500;
		border-radius: rem-num(20px);
		padding: rem-num(3px) rem-num(12px);
		background: rgba($white, .25);

		& button {
			margin-top: rem-num(1px);
			margin-bottom: rem-num(-1px);

			@if $enable-rtl {
				margin-left: rem-num(-5px);
				margin-right: rem-num(5px);
			}

			@else {
				margin-right: rem-num(-5px);
				margin-left: rem-num(5px);
			}

			&:hover {
				color: $white;
			}
		}
	}

	& .rti--input {
		background: none;
		color: $white;

		&::placeholder {
			color: rgba($white, .5);
			font-weight: $input-font-weight;
		}
	}
}