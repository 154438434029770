.widget-user-list {
	display: flex;
	align-items: center;

	& .widget-user-list-item {
		& .widget-user-list-link {
			border: 2px solid $gray-900;
			overflow: hidden;
			width: rem-num(40px);
			height: rem-num(40px);
			text-align: center;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 40px;

			& img {
				display: block;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&+.widget-user-list-item {
			@if $enable-rtl {
				margin-right: rem-num(-20px);
			}

			@else {
				margin-left: rem-num(-20px);
			}
		}
	}
}