/* Helper Margin */
@include helper-spacing('', 'margin', 'm');
@include helper-spacing('top', 'margin', 'mt');
@include helper-spacing('right', 'margin', 'me');
@include helper-spacing('bottom', 'margin', 'mb');
@include helper-spacing('left', 'margin', 'ms');
@include helper-spacing('mx', 'margin', 'mx');
@include helper-spacing('my', 'margin', 'my');


/* Helper Padding */
@include helper-spacing('', 'padding', 'p');
@include helper-spacing('top', 'padding', 'pt');
@include helper-spacing('right', 'padding', 'pe');
@include helper-spacing('bottom', 'padding', 'pb');
@include helper-spacing('left', 'padding', 'ps');
@include helper-spacing('px', 'padding', 'px');
@include helper-spacing('py', 'padding', 'py');

/* Helper Font */
@include helper-font-size(0, 80);
@include helper-font-weight(1, 8);

/* Helper Position Direction */
.top-0 {
	top: 0 !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.start-0 {
	left: 0 !important;
}

.end-0 {
	right: 0 !important;
}

.top-auto {
	top: auto !important;
}

.bottom-auto {
	bottom: auto !important;
}

.start-auto {
	left: auto !important;
}

.end-auto {
	right: auto !important;
}

/* Helper Text */
.text-underline {
	text-decoration: underline !important;
}

.text-gradient {
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}

.line-height-1 {
	line-height: 1 !important;
}

/* Helper Size */
@include helper-size('w', 'width');
@include helper-size('h', 'height');

/* Helper Color */
@each $color,
$value in $theme-colors {
	@include helper-color($color, $value);
}

@each $color,
$value in $colors {
	@include helper-color($color, $value);
}

.bg-none {
	background: none !important;
}

.bg-position-center {
	background-position: center !important;
}

.bg-size-cover {
	background-size: cover !important;
}

.bg-repeat-no-repeat {
	background-repeat: no-repeat !important;
}

@include background-gradient('bg-gradient-orange-red', lighten($orange, 10%), lighten($red, 10%));
@include background-gradient('bg-gradient-red-pink', lighten($red, 10%), lighten($pink, 10));
@include background-gradient('bg-gradient-yellow-orange', lighten($yellow, 10%), lighten($orange, 10%));
@include background-gradient('bg-gradient-yellow-red', lighten($yellow, 10%), lighten($red, 10%));
@include background-gradient('bg-gradient-yellow-green', lighten($yellow, 10%), lighten($green, 10%));
@include background-gradient('bg-gradient-cyan-blue', $cyan, $blue);
@include background-gradient('bg-gradient-cyan-indigo', $cyan, $indigo);
@include background-gradient('bg-gradient-custom-orange', lighten(mix($pink, $orange, 50%), 6%), lighten(mix($pink, $orange, 50%), 13%), right);
@include background-gradient('bg-gradient-custom-pink', lighten(mix($black, $pink, 20%), 14%), lighten(mix($black, $pink, 20%), 21%), right);
@include background-gradient('bg-gradient-custom-teal', mix($black, $teal, 18%), $teal, right);
@include background-gradient('bg-gradient-custom-indigo', lighten(mix($black, $indigo, 5%), 14%), lighten(mix($black, $indigo, 5%), 25%), right);
@include background-gradient('bg-gradient-custom-blue', $blue, lighten($blue, 10%), right);

.bg-gradient-indigo-teal {
	background-image: linear-gradient(to left, $indigo, $blue, $teal) !important;
}

/* Helper Opacity */
@include helper-opacity(0, 10);

/* Helper Sticky Top */
.desktop-sticky-top {
	@include media-breakpoint-up(xl) {
		position: sticky;
		top: $app-header-height + rem-num(20px);
	}
}

/* Helper Outline */
.outline-none {
	outline: none !important
}


@for $i from 2 through 5 {
	.bg-cover-#{$i}:after {
		background-image: url('images/cover-#{$i}.jpg');
	}

	.bg-cover-none:after {
		background-color: var(--bs-body-bg);
	}
}

/* Helper Flex */
.flex-1 {
	flex: 1 !important;
}

/* Helper Theme */
.list-group-item.active,
.page-item.active .page-link,
.form-check-input:checked,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	background-color: var(--bs-theme);
	border-color: var(--bs-theme);
}

.btn-theme {
	background-color: var(--bs-theme);
	border-color: var(--bs-theme);
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;

		border-radius: $border-radius;
		@include transition($btn-transition);
	}

	&.disabled,
	&:disabled {
		background-color: var(--bs-theme);
		border-color: var(--bs-theme);
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: var(--bs-theme);
		border-color: var(--bs-theme);

		&:before {
			background: rgba($white, .2);
		}
	}

	&:focus,
	&:active,
	&.active:focus,
	&:active:focus {
		@include box-shadow(0 0 0 0.25rem rgba(#{var(--bs-theme-rgb)}, .5));
	}
}

.btn-outline-theme {
	color: var(--bs-theme);
	border-color: var(--bs-theme);

	&:hover,
	&.active {
		background-color: var(--bs-theme);
		border-color: var(--bs-theme);
	}

	&.disabled,
	&:disabled {
		color: var(--bs-theme);
	}

	&:focus,
	&:active,
	&.active:focus,
	&:active:focus {
		@include box-shadow(0 0 0 0.25rem rgba(#{var(--bs-theme-rgb)}, .5));
	}
}

.nav-link {
	color: var(--bs-theme);

	&:hover {
		color: rgba(#{var(--bs-theme-rgb)}, .5);
	}
}