.sg-admin-layout {

  .body {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-col {
      .card-container {
        .card {
          margin: 10px;
          position: relative;

          .card-back {
            overflow: hidden;
            max-height: 300px;

            img {
              opacity: .3;
            }
          }

          .body {
            width: 100%;
            height: 100%;
            position: absolute !important;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .show-pt {
              margin-bottom: .3rem;
            }

            .ant-btn {
              border-radius: 15px;
            }
          }
        }

        .link-tab {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: center;
}